<template>
  <CRow>
    <CCol>
      <CCard>
        <CCardHeader>
          <strong>棚卸しサマリー</strong>
        </CCardHeader>

        <CCardBody>
          <CRow class="text-right mb-2">
            <CCol sm="12">
              <CButton color="primary" class="mr-2" @click="goToDetailPage">棚卸し商品詳細</CButton>
            </CCol>
          </CRow>
          <div v-if="loading">
            <LoadingCompoment />
          </div>
          <div v-else :key="key">
            <DataTable
              hover
              striped
              border
              :items="items"
              :fields="fields"
            >
            <td slot="difference" slot-scope="{item}" v-if="item.difference === 0">
              <div>{{ item.difference }}</div>
            </td>
            <td slot="difference" slot-scope="{item}" v-else v-bind:class = "(item.difference > 0) ? 'excess':'defficiency'">
              <div>{{ item.difference }}</div>
            </td>
            </DataTable>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import InventoryWork from "@/views/inventory-works/InventoryWorkFields";
import DataTable from "@/components/DataTable";
import inventoryWorkApiOther from '../../api/inventoryWorkApi';

export default {
  name: 'InventoryWorkSummary',
  components: {DataTable},
  data() {
    return {
      loading: false,
      inventoryWorkId: this.$route.params.id,
      items: [],
      fields: InventoryWork.summaryFields(),
      key: Math.random(),
    }
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;

      if (!this.inventoryWorkId) {
        this.loading = false;
        return;
      }

      inventoryWorkApiOther
        .getInventoryWorkSummary({id: this.inventoryWorkId})
        .then(response => {
          this.items = response.data;
          this.items.map((item) => {
            item.difference = item.in_inventory_count - item.in_stock_count;
          });
        }).finally(() => {
          this.loading = false;
        });
    },

    goToDetailPage() {
      this.$router.push({path: `/inventory-works/${this.inventoryWorkId}`})
    },
  }
}
</script>
<style scoped>
.defficiency {
  font-weight: bold;
  color: red;
}
.excess {
  font-weight: bold;
  color: blue;
}
</style>